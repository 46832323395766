// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from "jquery";
import Swiper from "swiper";

function show_ie_warning() {
  var ua = window.navigator.userAgent;
  if (ua.indexOf("MSIE") > 0 || ua.indexOf("Trident") > 0) {
    $("body").append(
      "<div id='iewarning'>Ihr Webbrowser (Internet Explorer) ist veraltet. Er wird nicht mehr aktualisiert.<br>Nutzen Sie bitte einen <a href='https://browser-update.org/de/update.html' target='_blank'>aktuellen Browser</a>, um diese Seite korrekt dazustellen.</div>"
    );
  }
}

// Cookies start
// function that may be run if consent is given
function google_gtm() {
  /*
    ga_id="UA-118075135-35"
    script=document.createElement("script");
    script0=document.getElementsByTagName("script")[0];
    script.async=1;
    script.src="https://www.googletagmanager.com/gtag/js?id="+ga_id;
    script0.parentNode.insertBefore(script,script0)
  
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', ga_id);*/
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0];
    var j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-NBX9PGJ");
}
function enable_youtube() {
  $("div.youtube").each(function () {
    id = $(this).data("youtube");
    $(this).removeClass("error");
    $(this).html(
      "<iframe width='100%' height='444' src='//www.youtube-nocookie.com/embed/" +
        id +
        "?rel=0' frameborder='0' allowfullscreen=''></iframe>"
    );
  });
}
/*
function load_stuff_if_cookie_is_set() {
  google_gtm();
  enable_youtube()
}*/

// basic functions
function save_cookie_settings(options, accepted) {
  if (accepted === undefined || accepted) {
    options.accepted = true;
  }
  var now = new Date();
  now.setTime(now.getTime() + 180 * 24 * 3600 * 1000); // 6 Monate
  document.cookie =
    "Cookies=" +
    JSON.stringify(options) +
    "; expires=" +
    now.toUTCString() +
    ";path=/";
}

function getCookieSettings() {
  var ca = document.cookie.split(/;\s*/);
  var opt = "";
  var name = "Cookies=";
  var i = 0;
  var options;
  for (ca, i = ca.length - 1; i >= 0; i--) {
    if (!ca[i].indexOf(name)) {
      opt = ca[i].replace(name, "");
    }
  }
  if (opt == "") {
    options = {
      accepted: false,
      google: false,
      facebook: false,
      video: false,
    };
    save_cookie_settings(options, false);
    return options;
  } else {
    return JSON.parse(opt);
  }
}

function cookie_consent_change_links() {
  // adjust the cookie consent table in the impressum according to the given choice
  var options = getCookieSettings();
  var erlauben = "<a href='#' class='erlauben' title='erlauben'></a>";
  var verweigern = "<a href='#' class='verweigern' title='verweigern'></a>";
  if ($("#cookie-google").length == 1) {
    if (options.google) {
      $("#cookie-google").html(verweigern);
    } else {
      $("#cookie-google").html(erlauben);
    }
  }
  if ($("#cookie-video").length == 1) {
    if (options.video) {
      $("#cookie-video").html(verweigern);
    } else {
      $("#cookie-video").html(erlauben);
    }
  }
  if ($("#cookie-facebook").length == 1) {
    if (options.facebook) {
      $("#cookie-facebook").html(verweigern);
    } else {
      $("#cookie-facebook").html(erlauben);
    }
  }
}

function do_stuff_depending_on_cookies() {
  var options = getCookieSettings();
  if (options.accepted) {
    // if cookies were accepted, remove the banner
    $("#cookie-info").remove();
  } else {
    if (!$("body").hasClass("cookie-overlay")) {
      $("#cookie-info").removeClass("hidden");
      $("body").css("padding-top", $("#cookie-info").height() + "px");
    }
  }
  if (options.google) {
    google_gtm();
  }
  if (options.video) {
    // include youtube videos
    //enable_youtube();
  }
  if (options.facebook) {
    // include facebook stuff
  }
  cookie_consent_change_links();
}

function disable_cookie_option(option) {
  var options = getCookieSettings();
  options[option] = false;
  save_cookie_settings(options);
  $("#cookie-" + option).html(
    "<a href='#' class='erlauben' title='erlauben'></a>"
  );
}

function enable_cookie_option(option) {
  var options = getCookieSettings();
  options[option] = true;
  save_cookie_settings(options);
  $("#cookie-" + option).html(
    "<a href='#' class='verweigern' title='verweigern'></a>"
  );
}

function initialze_cookie_choices() {
  // add actions to the cookie table in the impressum

  $("table.cookies td.cookieconsent").on("click", "a", function () {
    action = $(this).attr("class");
    option = $(this).closest("td").attr("id").replace("cookie-", "");
    if (action == "erlauben") {
      enable_cookie_option(option);
    } else {
      disable_cookie_option(option);
    }
    return false;
  });
}

function setup_cookies() {
  /*  if the COOKIE "Cookies" is not present at all, upgrade the warning to a lightbox */
  var options;
  if (document.cookie.indexOf("Cookies") === -1) {
    $("#cookie-info").removeClass("hidden");
    $("body").addClass("cookie-overlay");
  }

  do_stuff_depending_on_cookies();
  if ($("#cookie-info").length > 0) {
    // if there is a cookie warning on the page
    $("#cookies_are_ok").on("click", function () {
      options = {
        accepted: true,
        google: true,
        facebook: true,
        video: true,
      };
      save_cookie_settings(options);
      $("#cookie-info").remove();
      $("body").css("padding-top", 0);
      do_stuff_depending_on_cookies();
      return false;
    });
    $("#cookies_are_evil").on("click", function () {
      options = {
        accepted: false,
        google: false,
        facebook: false,
        video: false,
      };
      save_cookie_settings(options);
      $("#cookie-info").remove();
      $("body").css("padding-top", 0);
      do_stuff_depending_on_cookies();
      return false;
    });
    $("#cookies_close").on("click", function () {
      $("#cookie-info").remove();
      $("body").css("padding-top", 0);
      return false;
    });
  }
  initialze_cookie_choices();
}
// Cookies end

function enable_menu() {
  $("header a.hamburger").on("click", function () {
    $("menu").toggleClass("show");
  });
}
function inject_svg_icons() {
  $("[data-svg-icon]").each(function () {
    var icon = $(this).data("svg-icon");
    if ($(this).find("svg.icon").size() == 0) {
      $(this).prepend(
        '<svg class="icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/assets/icons.svg#' +
          icon +
          '"></use></svg>'
      );
    }
  });
}
function add_swipers() {
  $("div.swiperjs").each(function () {
    var options = {
      //  autoHeight:true
    };
    var perpage = parseInt($(this).data("swipe-perpage"));
    if (perpage > 0) {
      // get the width and fix it
      var w = $("article>div:first-child").width();
      //      console.log(w);
      $(this).css("width", w + "px");
      options.slidesPerView = perpage;
      //options.spaceBetween = 10;
      //    console.log("PERPAGE", perpage)
      options.breakpoints = {
        700: {
          slidesPerView: 2 * perpage,
          spaceBetween: 0,
        },
      };
    }

    $(this).addClass("swiper-container");
    $(this).css("padding", 0);
    $(this).children().addClass("swiper-slide");
    $(this).html("<div class='swiper-wrapper'>" + $(this).html() + "</div>");
    var id = $(this).attr("id");
    var auto = parseInt($(this).data("swipe-auto"));
    if (auto > 0) {
      options.autoplay = { delay: 1000 * auto };
    }
    //  console.log("ID ",id)
    var mySwiper = new Swiper("#" + id, options);
  });
}
function make_whole_divs_clickable() {
  $("div").on("click", ".with_link", function (e) {
    // $("div.with_link").on("click",function(e){
    // funktioniert nicht, da es Elemente gibt, die in Slider kopiert werden
    if (!$(e.target).is("A")) {
      var link = $(this).find("a");
      if (!!link.attr("target")) {
        window.open(link.attr("href"), "_blank");
      } else {
        location = link.attr("href");
      }
    }
  });
}
function fix_css() {
  // if an content block has the class "zwei_drittel" add it to the article
  if ($("article > div.zwei_drittel").length > 0) {
    $("article").addClass("zwei_drittel");
  }
}

$(document).ready(function () {
  show_ie_warning();
  setup_cookies();

  enable_menu();
  inject_svg_icons();
  make_whole_divs_clickable();
  fix_css();
  add_swipers();
});
